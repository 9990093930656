<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-if="skin == 'light'"
          style="max-width: 140px"
          src="@/assets/images/logo/APP-LIGHT.png"
          alt="logo"
        />

        <b-img
          v-else
          style="max-width: 140px"
          src="@/assets/images/logo/APP-DARK.png"
          alt="logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Tprm 👋
          </b-card-title>
          <b-card-text class="mb-2 text-primary">
            Two Factor Authentication Enabled in Your Account Please Enter the OTP to
            Continue.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group
                label="Enter OTP from your authenticator App"
                label-for="login-email"
              >
                <validation-provider #default="{ errors }" name="OTP" rules="required">
                  <b-form-input
                    id="login-email"
                    v-model="otp_2fa"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click="validationForm">
                Continue
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{ name: 'register' }">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text>
  
          
            <div class="divider my-2">
              <div class="divider-text">or</div>
            </div>
  
           
            <div class="auth-footer-btn d-flex justify-content-center">
              <b-button variant="facebook" href="javascript:void(0)">
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button variant="twitter" href="javascript:void(0)">
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button variant="google" href="javascript:void(0)">
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button variant="github" href="javascript:void(0)">
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ResponseMixins from "../../mixins/ResponseMixins";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, ResponseMixins],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      first_token: "",
      otp_2fa: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    this.first_token = this.$route.params.id;
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          const data = {
            first_auth_token: this.first_token,
            one_time_password: this.otp_2fa,
          };
          this.$store
            .dispatch("app/login", data, { root: true })
            .then(
              () => {
                this.$store
                  .dispatch("app/getCurrentUserDetails", { root: true })
                  .then((resp) => {
                    console.log(
                      "Dashboard CurrentId",
                      $cookies.get("currentCID"),
                      Date.now()
                    );
                    // console.log(resp)
                    //------------If User is Vendor-------------------------
                    if (
                      resp.data.data.user_type &&
                      resp.data.data.user_type == "Vendor"
                    ) {
                      this.$store.commit("app/set_userData", resp.data.data);
                      const url = "/";
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Welcome ${resp.data.data.firstname} ${resp.data.data.lastname}`,
                          icon: "UserCheckIcon",
                          variant: "success",
                        },
                      });
                      return this.$router.replace(url);
                    }
                    // ---------------------If User is normal User
                    else {
                      const url = "/";
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Welcome ${resp.data.data.firstname} ${resp.data.data.lastname}`,
                          icon: "UserCheckIcon",
                          variant: "success",
                        },
                      });
                      return this.$router.replace(url);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    const url = "/login";
                    this.$router.replace(url);
                    this.handleError(err);
                  });
              }
              // this.$router.push({ name: 'home'})
            )
            .catch((err) => {
              console.log(err);
              this.handleError(err);
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Unable to log in with provided credentialss.",
              //     icon: "AlertTriangleIcon",
              //     variant: "error",
              //   },
              // });
            })
            .finally(() => {
              // localStorage.removeItem("from_url");
              // this.$router.go()
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
